import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import ConsulationCta from "../components/ConsultationCta"
import Layout from "../components/layout"
import ResourceCard from "../components/ResourceCard"
import SEO from "../components/seo"
import SoftwareCta from "../components/SoftwareCta"
import Map from "../images/home-map.inline.svg"
import JoeJarroush from "../images/joe_jarroush.png"
import LooptyLoop from "../images/loopty-loop.inline.svg"

const IndexPage = ({ data }) => {
  const { allPagesYaml } = data
  return (
    <Layout>
      <SEO title="Home" />

      <div className="header header--intro">
        <div className="container grid">
          <div className="header-content">
            <h1>{allPagesYaml.edges[0].node.title}</h1>
          </div>
        </div>
      </div>

      <div className="intro">
        <div className="container grid">
          <figure className="loopty-loop">
            <LooptyLoop />
          </figure>

          <div className="intro-content">
            <p className="h4">{allPagesYaml.edges[0].node.description}</p>
            <Link
              className="intro-button button button--plane button--large button--grey"
              to="/contact"
            >
              Contact for Demo
            </Link>
          </div>

          <div className="intro-img">
            <figure className="figure-lighten">
              <Img
                fluid={data.Hero.childImageSharp.fluid}
                alt="hero warehouse"
              />
            </figure>
          </div>
        </div>

        <div className="intro__extension container grid">
          <figure className="intro-stats__map">
            <Map />
          </figure>

          <div className="intro-stats">
            <h2>Empowering growing businesses nationwide</h2>

            <div className="stats">
              <div className="stats-statline">
                <div className="stat-box">
                  <h3 className="stat-big">
                    {allPagesYaml.edges[0].node.stat_stores}
                  </h3>
                  <p className="stat-desc">stores</p>
                </div>

                <div className="stat-box">
                  <h3 className="stat-big">
                    {allPagesYaml.edges[0].node.stat_listings} <span>m</span>
                  </h3>
                  <p className="stat-desc">listings</p>
                </div>
                <div className="stat-box">
                  <h3 className="stat-big">
                    {allPagesYaml.edges[0].node.stat_sales} <span>m</span>
                  </h3>
                  <p className="stat-desc">sales</p>
                </div>
              </div>
            </div>
          </div>

          <div className="intro-quote">
            <div className="h3">{allPagesYaml.edges[0].node.testimonial}</div>

            <div className="intro-quote__author">
              <div>
                <h4 className="h4--bold">
                  {allPagesYaml.edges[0].node.testimonial_author}
                </h4>
                <p>{allPagesYaml.edges[0].node.testimonial_role}</p>
              </div>
              <img
                alt="Testimonial author"
                className="intro-quote__avatar"
                src={JoeJarroush}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="services">
        <div className="container">
          <Link
            to="/services"
            className="link link__title link--arrow-right services__link"
          >
            Services
          </Link>

          <div>
            {data.allServicesYaml.edges.map(({ node }, index) => {
              return (
                <div
                  key={index}
                  className={`service ${index % 2 !== 0 && "service--rtl"}`}
                >
                  <div className="service-content__container">
                    <div className="service-indicator">
                      <img
                        alt=""
                        className="service-indicator__icon"
                        src={node.icon.publicURL}
                      ></img>

                      <div className="service-indicator__line">
                        <div className="service-indicator__line--vertical" />
                        <div className="service-indicator__line--corner" />
                      </div>
                    </div>

                    <div className="service-content">
                      <h2 className="service-content__title h2 h2--bold">
                        {node.title}
                      </h2>

                      <p>{node.description}</p>

                      <Link
                        to={`/services#${node.title}`}
                        className="button button--blue-light"
                      >
                        See services
                      </Link>
                    </div>
                  </div>

                  <div className="service-image__container">
                    <Img
                      className="service-image"
                      fluid={node.thumbnail.childImageSharp.fluid}
                      alt={node.title}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <ConsulationCta />

      <SoftwareCta />

      <div className="container resources--home">
        <div className="resources__header resources__header--home">
          <Link to="/resources" className="link link__title link--arrow-right">
            Resources
          </Link>

          <div className="header__buttons">
            <Link
              to="/case-studies"
              className="header__button button button--beige button--arrow-right"
            >
              Case Studies
            </Link>
            <Link
              to="/blog"
              className="header__button button button--beige button--arrow-right"
            >
              Blog
            </Link>
            {/* <Link
              to="/tools"
              className="header__button button button--beige button--arrow-right"
            >
              Tools
            </Link> */}
          </div>
        </div>

        <div className="resources-list">
          {data.allMarkdownRemark.edges.map(({ node }) => {
            return (
              <ResourceCard
                key={node.frontmatter.title}
                slug={node.frontmatter.slug}
                thumbnail={node.frontmatter.thumbnail}
                title={node.frontmatter.title}
                category={node.frontmatter.type}
                tags={node.frontmatter.tags}
              />
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allServicesYaml(sort: { fields: title }) {
      edges {
        node {
          title
          description
          offerings {
            description
            title
          }
          icon {
            publicURL
          }
          thumbnail {
            childImageSharp {
              fluid(maxHeight: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    allPagesYaml(filter: { page_key: { eq: "home" } }) {
      edges {
        node {
          id
          description
          title
          stat_listings
          stat_sales
          stat_stores
          testimonial
          testimonial_author
          testimonial_role
        }
      }
    }

    allMarkdownRemark(
      filter: { frontmatter: { draft: { eq: false } } }
      limit: 3
      sort: { fields: [frontmatter___published], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            tags
            type
            slug
            published
            thumbnail {
              childImageSharp {
                fluid(maxHeight: 168, maxWidth: 336) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    Jarroush: file(relativePath: { eq: "joe_jarroush.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    Hero: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage
